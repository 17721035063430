import React from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Link, TextField } from '@mui/material'
import Modal from '@mui/material/Modal';
import Divider from '@mui/material/Divider';
import { ModalProps, ModalPropsBase } from '../../types/widget-types';
import styled from '@emotion/styled';
import clsx from 'clsx';


const CustomDialog = (props: any) => {
    return <Dialog
        {...props}
        className={
            clsx({
                'sizeSmall': props.size == 'sm',
                'sizeLarge': props.size == 'lg',
                'sizeMedium': props.size == 'md'
            })
        }
    />
}



function ModalWidget({ title, children, actions, size, modalOpen, onClose, onConfirm }: ModalPropsBase) {


    const onCloseClick = () => {
        onClose?.()
    }

    const onConfirmClick = () => {
        onConfirm?.();
        onClose?.();
    }


    return (
        <>
            <CustomDialog
                open={modalOpen ?? false}
                onClose={() => onCloseClick()}
                size={size ?? 'sm'}
                sx={{ border: 0 }}

            >
                <DialogTitle variant='h6' color={"primary"}>
                    {title}
                </DialogTitle>
                <Divider />
                <DialogContent sx={{ pt: '25px', 'fontSize': "16px" }}>
                    {children}
                </DialogContent>


            </CustomDialog>
        </>
    )
}

export default ModalWidget