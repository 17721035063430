import React, {  useEffect, useRef, useState } from 'react'
import BasicTabs from '../../../shared/hoc/tabs/Tabs'

import { useTranslation } from 'react-i18next'
import DashBoard from '../event-management/event-users';
import { gql, useQuery } from '@apollo/client';
import { useUpdateEffect } from '../../../shared/custom-hooks/useUpdateEffect';
import { useParams } from 'react-router-dom';



const QUERY = gql`query GetAllEventVisiorsCount($eventId: Int, $search: String, $page: Int, $pageSize: Int) {
  getAllEventVisiorsCount(eventId: $eventId, search: $search, page: $page, pageSize: $pageSize) {
    success
    errors
    allTotalVisitors
    totalApprovedVisitors
    totalSentQRVisitors
    totalOpenVisitors
    totalRejectedVisitors
    totalVisitedVisitors
  }
}
      `

function EventDetailVisitorsPage() {

    const {eventid}:any = useParams();

    const { refetch: visitorsRefetch } = useQuery(QUERY, { skip: true });
    const [visitorsCount, setVisitorsCount] = useState("")
    const [OpenVisitorsCount, setOpenVisitorsCount] = useState("")
    const [rejectedVisitorsCount, setRejectedVisitorsCount] = useState("")
    const [sentTicketVisitorsCount, setSentTicketVisitorsCount] = useState("")
    const [visitedVisitorsCount, setVisitedTicketVisitorsCount] = useState("")
    const [sentQrVisitorsCount, setSentQrVisitorsCount] = useState("")
    // const [eventId, setEventId]:any = useState(null);
    const childRef = useRef<ChildRef | null>(null);

    // const {eventid} = useParams();
    // setEventId(eventid)

    type ChildRef = {
        refetchData: (data: any) => void;
    };

    useEffect(() => {
        getAllVisitors();
    }, [])


    useUpdateEffect(() => {
        getAllVisitors();
    }, [], 300)

    const getAllVisitors = async () => {
        console.log("function call.......")
        const { data } = await visitorsRefetch({
            eventId:parseInt(eventid),
            search: "",
            page: null,
            pageSize: null
        });
        setVisitorsCount(data?.getAllEventVisiorsCount?.allTotalVisitors)
        setOpenVisitorsCount(data?.getAllEventVisiorsCount?.totalOpenVisitors)
        setSentQrVisitorsCount(data?.getAllEventVisiorsCount?.totalSentQRVisitors)
        setRejectedVisitorsCount(data?.getAllEventVisiorsCount?.totalRejectedVisitors)
        setSentTicketVisitorsCount(data?.getAllEventVisiorsCount?.totalApprovedVisitors)
        setVisitedTicketVisitorsCount(data?.getAllEventVisiorsCount?.totalVisitedVisitors)
    }

    console.log(visitorsCount)

    return (
        <div>
            <BasicTabs tabsData={[
                {
                    label: `All ${visitorsCount}`, content: () => (
                        <div>
                            <DashBoard onUpdateTotalVisitors={getAllVisitors} eventId={eventid}/>
                        </div>
                    )
                },
                {
                    label: `Open ${OpenVisitorsCount}`, content: () => (
                        <div>
                            <DashBoard status="Open" onUpdateTotalVisitors={getAllVisitors} eventId={eventid}/>
                        </div>
                    )
                },
                {
                label: `Sent QR ${sentQrVisitorsCount}`, content: () => (
                        <div>
                            <DashBoard status="Sent QR" onUpdateTotalVisitors={getAllVisitors} eventId={eventid}/>
                        </div>
                    )
                },
                {
                    label: `Sent Ticket ${sentTicketVisitorsCount}`, content: () => (
                        <div>
                            <DashBoard status="Sent Ticket" onUpdateTotalVisitors={getAllVisitors} eventId={eventid} />
                        </div>
                    )
                },
                {
                    label: `Rejected ${rejectedVisitorsCount}`, content: () => (
                        <div>
                            <DashBoard status="Rejected" onUpdateTotalVisitors={getAllVisitors} eventId={eventid}/>
                        </div>
                    )
                },
                {
                    label: `Visited ${visitedVisitorsCount}`, content: () => (
                        <div>
                            <DashBoard status="Visited" onUpdateTotalVisitors={getAllVisitors} eventId={eventid} />
                        </div>
                    )
                },
            ]} />
        </div>
    )
}

export default EventDetailVisitorsPage