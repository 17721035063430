import React, { useState } from 'react'
import { FormField, FormGroup } from '../../../shared/utils/form-generator'
import TextField from '../../../shared/widgets/text-field/TextField'
import { Button, Divider, Grid, MenuItem } from '@mui/material'
import { gql, useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack';
import { parseDate } from '../../../shared/utils/util-functions'
import DateField from '../../../shared/widgets/date-field/DateField'
import moment from 'moment'


const UPDATE_EVENT_QUERY = gql`mutation UpdateEvent(
  $updateEventId: ID!
  $title: String
  $description: String
  $date: String
  $location: String
  $eventType: String
) {
  updateEvent(
    id: $updateEventId
    title: $title
    description: $description
    date: $date
    location: $location
    eventType: $eventType
  ) {
    success
    errors
  }
}
`

const CREATE_EVENT_QUERY = gql`mutation Mutation($title: String!, $date: String!, $eventType: String!, $description: String, $location: String) {
  createEvent(title: $title, date: $date, eventType: $eventType, description: $description, location: $location) {
    success
    event {
      title
      location
      id
      eventType
      description
      date
    }
    errors
  }
}`



type ChildRef = {
    refetchData: (data: any) => void;
};


export default function CreateEvent({ users, onSave, onClose, editMode }: any) {

    console.log("event-----",users)

    const { enqueueSnackbar } = useSnackbar();
    const [createEvent] = useMutation(CREATE_EVENT_QUERY);
    const [updateEvent] = useMutation(UPDATE_EVENT_QUERY);
    const [resetField, setResetField] = useState<Function | undefined>(undefined);

    

    const onCloseEvent = () => {
        onClose();
    }

    const eventTypes = [
        {
            value: 'Workshop',
            label: 'Workshop',
        },
        {
            value: 'Annual',
            label: 'Annual',
        },

    ];

    const handleSubmit = async (user: any, callback: Function) => {
        console.log('-----data', user)
        if (users) {
            const variables = {
                updateEventId: users.id,
                title: user.title,
                description: user.description,
                date: user.date,
                location: user.location,
                eventType: user.eventType,

            }
            try {
                const { data } = await updateEvent({
                    variables: variables
                });

                if (data.updateEvent.errors?.length > 0) {
                    console.error('Error:', data.updateEvent.errors);
                    enqueueSnackbar(data.updateEvent.errors[0], { variant: "error" });
                    callback();
                } else {
                    console.log('event details updated');
                    enqueueSnackbar(("Updated successfully"), { variant: "success" });
                    onSave()
                }
            } catch (error) {
                console.error('Mutation error:', error);
                callback();
            }

        } else {
            const variables = {
                title: user.title,
                description: user.description,
                date: user.date,
                location: user.location,
                eventType: user.eventType,
            }
            try {
                const { data } = await createEvent({
                    variables: variables
                });

                if (data.createEvent.errors?.length > 0) {
                    console.error('Error:', data.createEvent.errors);
                    enqueueSnackbar(data.createEvent.errors[0], { variant: "error" });
                    callback();
                } else {
                    console.log('Event created:', data.createEvent.user);
                    enqueueSnackbar(("Event created successfully"), { variant: "success" });
                    onSave()
                }
            } catch (error) {
                console.error('Mutation error:', error);
                callback();
            }
        }

    };


    return (
        <div>
            <FormGroup onSubmit={handleSubmit} onFormInit={(resetField, resetForm) => setResetField((name) => resetField)}>
                <Grid container>
                    <Grid xs={12} >
                        <FormField name='title' value={users?.title ?? ""} validator={(value: string, ...data) => {
                            if (!value) return "Title is required."
                            return null
                        }} validateOnChange={false}>
                            <TextField id="title"
                                label="Title"
                                variant="outlined"
                                sx={{ mb: 10 }}
                                fullWidth={true}
                            />
                        </FormField>
                    </Grid>

                    <Grid xs={12} >
                        <FormField name='description' value={users?.description ?? ""} validator={(value: string) => {
                            if (!value) {
                                return 'Description is required.'
                            }
                            return null
                        }} validateOnChange={false}>
                            <TextField id="description"
                                label="Description"
                                variant="outlined"
                                sx={{ mb: 10 }}
                                fullWidth={true}
                                multiline
                                rows={2}
                            />
                        </FormField>
                    </Grid>

                    <Grid item xs={12}>

                        <FormField name="date" value={users?.date ?? ''} validator={(value: string) => {
                            if (!value) return "Date of birth is required."
                            let date = parseDate(value);
                            if (!date) return "Invalid Date"
                            return null
                        }} validateOnChange={false}>
                            <DateField id="Name" label="Date Of Event" minDate={moment(new Date())} sx={{ mb: 10 }} fullWidth={true} />

                        </FormField>
                    </Grid>



                    <Grid xs={12} >
                        <FormField name='location' value={users?.location ?? ""} validator={(value: string) => {
                            if (!value) {
                                return 'Location is required.'
                            }
                            return null
                        }} validateOnChange={false}>
                            <TextField id="location"
                                label="Location"
                                variant="outlined"
                                sx={{ mb: 10 }}
                                fullWidth={true}
                            />
                        </FormField>
                    </Grid>
                    <Grid xs={12} >
                        <FormField name='eventType' value={users?.eventType ?? ""} validator={(value:string)=>{
                                 if (!value) {
                                    return 'Event type is required.'
                                }
                                return null
                        }} >
                            <TextField id="eventType"
                                label="Event type"
                                select
                                variant="outlined"
                                sx={{ mb: 10 }}
                                fullWidth={true}
                            >
                                {eventTypes.map((option) => (
                                    <MenuItem key={option.value} value={option.value} >
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormField>
                    </Grid>
                </Grid>
                <Divider sx={{ mx: -24, mb: 20 }} />
                <Grid container justifyContent={"flex-end"}>
                    <Grid item xs={"auto"}>
                        <Button type="button" variant="text" color="secondary" onClick={onCloseEvent} >Cancel</Button>
                    </Grid>
                    <Grid item xs={"auto"}>
                        {
                            users ? <Button type="submit" variant="contained" color="primary">Save</Button> :
                                <Button type="submit" variant="contained" color="primary">Register</Button>
                        }

                    </Grid>
                </Grid>
            </FormGroup>
        </div >
    )
}
