import React, { useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { stopPageLoading } from '../../../store/reducers/AppSlice';

function GuestPage() {
    const isLogged = useSelector((state: any) => state.auth.isLoggedIn)
    const role = useSelector((state: any) => state.auth.role)
    const dispatch = useDispatch();
    console.log("-----",role)
    let path;
    if (role === 'BBS Member') {
        path = "/admin/dashboard";
    } else {
        path = "/admin/scan";
    }

    let redirectPath = localStorage.getItem('redirectPath');
    if (redirectPath) {
        path = redirectPath as string;
    }

    useEffect(() => {
        dispatch(stopPageLoading())
    }, [])

    return (
        <>
            {
                isLogged ? (
                    <Navigate to={path} />
                ) : (
                    <Outlet />
                )

            }
        </>
    )
}

export default GuestPage