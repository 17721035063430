import React, { useEffect, useState } from 'react'
import TextField from '../../../shared/widgets/text-field/TextField'
import { Box, Button, Grid, MenuItem } from '@mui/material'
import { FormField, FormGroup } from '../../../shared/utils/form-generator';
import { gql, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';


const GET_EVENT_YEARS = gql`query Query {
  getEventYears
}
  `

function FilterEvent({ filterData, onFilterChange, onClose }: any) {

    const { t } = useTranslation();
    const [resetForm, setResetForm] = useState<any>(null);
    const [eventsYear, setEventsYear] = useState<any>(null);
    const { refetch: eventYearRefetch } = useQuery(GET_EVENT_YEARS, { skip: true });

    const eventTypes = [
        {
            value: 'Workshop',
            label: 'Workshop',
        },
        {
            value: 'Annual',
            label: 'Annual',
        },

    ];

    console.log("filter event----",filterData)

    const handleSubmit = async (user: any, callback: Function) => {

        const filters = {
            eventType: user.eventType || null,
            year: user.year || null,
        };

        onFilterChange({
            submit: true,
            filters: filters,
            callback
        });

        onClose();

    };

    const getUniqueYears = async ()=> {
        const { data } = await eventYearRefetch();
        setEventsYear(data);
    }

    useEffect(()=>{
        getUniqueYears()
    },[])

    console.log(eventsYear)

    return (
        // <div>
        <FormGroup onSubmit={handleSubmit} onFormInit={(resetField, resetForm) => setResetForm(() => resetForm)}>
            <Box sx={{ width: '300px', padding: '8px 16px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <FormField name='eventType' value={filterData?.eventType ?? ""} >
                    <TextField id="roles"
                        label={t("Event type")}
                        select
                        variant="outlined"
                        sx={{ mb: 10 }}
                        fullWidth={true}
                    >
                        {eventTypes.map((option) => (
                            <MenuItem key={option.value} value={option.value} >
                                {t(option.label)}
                            </MenuItem>
                        ))}
                    </TextField>
                </FormField>

                <FormField name='year' value={filterData?.year ?? ""} >
                    <TextField id="year"
                        label={t("Year")}
                        select
                        variant="outlined"
                        sx={{ mb: 10 }}
                        fullWidth={true}
                    >
                        {eventsYear?.getEventYears.map((year:any) => (
                            <MenuItem key={year} value={year}>
                                {year}
                            </MenuItem>
                        ))}
                    </TextField>
                </FormField>

                <Grid sx={{ alignSelf: 'end' }}>
                    <Button type="button" variant="text" onClick={() => {
                        resetForm();

                        onFilterChange({
                            submit: true,
                            filters: {
                                eventType: null,
                            },
                        })
                    }} color="primary">{t('Clear')}</Button>
                    <Button type="submit" variant="contained" color="primary">{t('Filter')}</Button>
                </Grid>
            </Box>
        </FormGroup>
        // </div>
    )
}

export default FilterEvent