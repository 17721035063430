// QrScanner.tsx
import React, { useRef, useState } from 'react';
import QrReader from 'react-qr-scanner';

interface QrScannerProps {
  onScan: (data: string | null) => void;
  onError: (err: any) => void;
}

const QrScanner: React.FC<QrScannerProps> = ({ onScan, onError }) => {
  const previewStyle = {
    width: '275px',
    height: '275px',
  };

  const [scannedData, setScannedData] = useState<string | null>(null);
  const scannerRef = useRef<any>(null);

  const handleScan = (data: any) => {
    if (data) {
      setScannedData(data.text);
      onScan(data.text);
      
    }
  };

  console.log("----qr data",scannedData)

  return (
    <QrReader
      ref={scannerRef}
      delay={300}
      style={previewStyle}
      onError={onError}
      onScan={handleScan}
      constraints={{
        audio: true,
        video: { facingMode: "environment" }
      }}  
    />
  );
};

export default QrScanner;
