import { PaletteOptions, SimplePaletteColorOptions, ThemeOptions, alpha, createTheme } from "@mui/material";


interface CustomPaletteOptions extends SimplePaletteColorOptions {
    main: string;
    50?: string;
    100?: string;
    200?: string;
    300?: string;
    400?: string;
    500?: string;
    600?: string;
    700?: string;
    800?: string;
    900?: string;
    A100?: string;
    A200?: string;
    A400?: string;
    A700?: string;
}




export const generateBase = ({ mode }: any): ThemeOptions => {


    const primary: CustomPaletteOptions = Object.freeze({
        main: '#2e9fff',
        contrastText: '#fff',
        dark: '#002e55',
        light: '#eaf5ff',
        50: '#eaf5ff',
        100: '#bbe0ff',
        200: '#4DA7D4',
        300: '#8ccaff',
        400: '#5db4ff',
        500: '#2e9fff',
        600: '#1c84dd',
        700: '#0d6bbb',
        800: '#035499',
        900: '#004077',
        A100: '#002e55',
    });


    const secondary: CustomPaletteOptions = Object.freeze({
        main: '#00ffa3',
        contrastText: '#fff',
        dark: '#7A570F',
        light: '#esfff6',
        50: '#esfff6',
        100: '#b7ffe5',
        200: '#8affd4',
        300: '#5cffc4',
        400: '#2effb3',
        500: '#00ffa3',
        600: '#00b689',
        700: '#00ad6f',
        800: '#009255',
        900: '#008555',
        A100: '#005c3b',
    });

    const grey: CustomPaletteOptions = {
        main: '#6B6D70',
        contrastText: '#fff',
        "100": "#F7F6FA",
        "200": "#EEECF9",
        "300": "#A8B6C1",
        "400": "#6B6D70",
        "500": "#343A40",
    };


    const pallete: PaletteOptions = {
        mode: mode,
        primary: primary,
        secondary: secondary,
        background: {
            default: mode == 'light' ? '#ffffff' : '#2a2b35',
            paper: mode == 'light' ? '#FAFAFB' : '#15151a',
        },
        success: {
            main: '#72C600'
        },
        info: {
            main: '#19A2B8'
        },
        warning: {
            main: '#FEC12D'
        },
        error: {
            main: '#FF0303'
        },
        text: {
            primary: mode == 'light' ? '#6E6E6E' : "#d6d0d0",
            secondary: mode == 'light' ? '#313131' : "#d6d0d0",
            disabled: '#6E6E6E'
        },
        grey: grey,
        common: {
            white: '#ffffff',
            black: '#000000',
        }
    }


    return (
        {

            palette: pallete,
            typography: {
                fontFamily: [
                    '"Poppins"',
                    'sans-serif',
                ].join(','),


                h3: {
                    fontSize: '3.2rem',
                    lineHeight: '4.6rem',
                    fontWeight: '700',
                    color: primary[600]
                },

                h6: {
                    fontSize: '2.4rem',
                    fontWeight: '700',
                    lineHeight: '3.5rem',
                    color: primary[600]
                },
                subtitle1: {
                    fontSize: '1.8rem',
                    fontWeight: 400,
                    lineHeight: '2.5rem',
                    color: grey[300]

                },
                subtitle2: {
                    fontSize: '1.5rem',
                    fontWeight: 500,
                    lineHeight: '2.1rem',

                },
                body1: {
                    fontSize: '1.6rem',
                    fontWeight: 400,
                    lineHeight: '2.2rem'
                },
                htmlFontSize: 16,

            },
            spacing: (factor: number) => `${factor}px`,
            shape: {
                borderRadius: 4
            },
            components: {
                MuiCssBaseline: {
                    styleOverrides: {
                        html: {
                            scrollBehavior: 'smooth',
                            fontSize: '10px',
                        },
                        body: {
                            fontSize: '10px',
                        }
                    }
                },
                MuiOutlinedInput: {
                    styleOverrides: {
                        input: {
                            fontSize: '1.6rem',
                            lineHeight: '2.2rem',
                            padding: '1rem 1.6rem',
                            color: 'rgba(107, 109, 112, 0.8)',
                            // color: '#4D4D60'
                        },
                        notchedOutline: {
                            borderColor: grey[300],
                            fontSize: '1.6rem',
                        },
                        inputMultiline:{
                            padding:'0px'
                        }
                    }
                },
                MuiInputLabel: {
                    styleOverrides: {
                        root: {
                            fontSize: '1.6rem',
                            lineHeight: '2.2rem',
                            color: alpha(grey.main, 1),
                            transform: 'translate(14px, 11px) scale(1)',


                        },
                        shrink: {
                            transform: 'translate(14px, -9px) scale(0.75)',
                        },

                    }
                },
                MuiInputBase: {
                    styleOverrides: {
                        root: {
                            '&:-webkit-autofill': {
                                '-webkit-box-shadow': 'none'
                            },
                            '.inputMultiline':{
                                padding:'0px'
                            }
                        },
                   
                    }
                },
            
                MuiCheckbox: {
                    styleOverrides: {
                        root: {
                            lineHeight: '2.1rem',
                        },
                    }
                },
                MuiFormControlLabel: {
                    styleOverrides: {
                        root: {
                            fontSize: '1.5rem',
                            lineHeight: '2.1rem',
                        },
                    }
                },
                MuiFormHelperText: {
                    styleOverrides: {
                        root: {
                            fontSize: '1.2rem',
                            lineHeight: '1.7rem',
                        },
                    }
                },
                MuiLink: {
                    styleOverrides: {
                        root: {
                            fontSize: '1.5rem',
                            lineHeight: '2.1rem',
                            fontWeight: 500,
                        }
                    }
                },

                MuiSvgIcon: {
                    styleOverrides: {
                        root: {
                            fontSize: '1.8rem',
                        }
                    }
                },
                MuiButton: {
                    styleOverrides: {
                        root: {
                            fontWeight: 400,
                            borderRadius: '0px',
                            padding: '9px 25px',
                            fontSize: '16px',
                            lineHeight: '22px',
                            textTransform: 'uppercase',
                        },
                        sizeLarge: {
                            padding: '13px 20px',
                        },
                        sizeSmall: {
                            padding: '5px 10px',
                        },
                        outlined: {
                            boxShadow: `0px 3px 3px ${grey?.[200]}`,
                        },
                        textSecondary: {
                            // color: alpha(grey.main, 0.8),
                            color: '#75758E'

                        },
                        containedPrimary: {
                            backgroundColor: primary[800],
                            color: primary.contrastText,
                            '&:hover': {
                                backgroundColor: primary.dark,
                            },
                        },
                    }
                },


                MuiTableHead: {
                    styleOverrides: {
                        root: {
                            // height: '4.0rem',
                            padding: '8px 20px',
                            // borderBottom: '2px solid #ebf5fa',
                            // borderBottom: `2px solid ${alpha(primary.light ?? primary.main , 0.1)}`,
                            fontSize: '1.6rem',
                            lineHeight: '2.2rem',
                            whiteSpace: 'nowrap'

                        }
                    },
                },


                MuiTableRow: {
                    styleOverrides: {
                        root: {
                            backgroundColor: "#262632",
                            '&.MuiTableRow-head': {
                                // backgroundColor: alpha(primary.light ?? primary.main , 0.5),
                                backgroundColor: '#e4e8f2 !important',

                            },

                            '&:nth-of-type(even)': {
                                // backgroundColor: alpha(primary.light ?? primary.main , 0.5),
                                backgroundColor: "#1C1C24",

                            },

                        },
                    }
                },
                MuiTableCell: {
                    styleOverrides: {
                        root: {
                            fontSize: '1.6rem',
                            // height: '5.0rem',
                            padding: '8px 20px',
                            lineHeight: '2.2rem',
                            whiteSpace: 'nowrap',
                            borderBottom: 'none',

                            // '&:first-child': {
                            //     color: '#343A40'
                            // },
                        },
                        head: {
                            fontWeight: 'bold',
                            color: grey[500],
                            padding: '8px 20px',
                        },
                        body: {
                            lineHeight: '2.2rem'
                        }
                    }
                },
                MuiPaper: {
                    styleOverrides: {
                        root: {
                            borderRadius: '0px',
                            boxShadow: 'none',

                            // '.MuiMenu-Paper': {
                            //     backgroundColor: "#262632"
                            // }

                        }

                    }
                },
                MuiSnackbar: {
                    styleOverrides: {
                        root: {
                            fontSize: '14px'
                        }
                    }
                },

                MuiPagination: {
                    styleOverrides: {
                        root: {
                            fontSize: '1.4rem',
                            border: `1px solid ${grey?.[200]}`,
                            borderRadius: '5px',
                            overflow: 'hidden',

                            '& li:not(:last-child)': {
                                'button::after': {
                                    content: '""',
                                    height: "calc(100% - 6px)",
                                    width: '1px',
                                    position: 'absolute',
                                    backgroundColor: `${grey?.[200]}`,
                                    top: '3px',
                                    right: '-1px'
                                },
                                'div::after': {
                                    content: '""',
                                    height: "calc(100% - 6px)",
                                    width: '1px',
                                    position: 'absolute',
                                    backgroundColor: `${grey?.[200]}`,
                                    top: '3px',
                                    right: '-1px'
                                }
                            },
                        },
                        ul: {
                            listStyle: 'none',
                            padding: '0',
                            margin: '0',
                            display: 'flex',
                            justifyContent: 'end',
                        },
                    }
                },

                MuiPaginationItem: {
                    styleOverrides: {
                        root: {
                            height: '34px',
                            width: '42px',
                            padding: '9px 17px 8px 17px',
                            borderRadius: '0',
                            margin: '0',
                            position: 'relative',

                            // border: '1px solid black'
                            "&.Mui-selected": {
                                backgroundColor: secondary.main + ' !important',
                                // color: secondary.contrastText,
                                color: primary.dark,
                                pointerEvents: 'none',

                                '&::after': {
                                    display: 'none'
                                },
                            }

                        },
                        page: {
                            // color: '#858585',
                            fontSize: '1.4rem',
                            fontFamily: 'Roboto',
                            lineHeight: '1.7rem'
                        },

                        previousNext: {
                            // backgroundColor: 'red'
                        },
                        outlinedSecondary: {
                            border: 'none',
                            position: 'relative',
                        },

                    }
                },
                MuiList: {
                    styleOverrides: {
                        root: {
                            padding: '0px 0px',
                            margin: '0',
                            fontSize: '16px',
                            lineHeight: '22px',
                            backgroundColor: '#262632'
                        },

                    }
                },
                MuiMenuItem: {
                    styleOverrides: {
                        root: {
                            // marginBottom:'20px',
                            fontSize: '16px',
                            lineHeight: '22px',
                            padding: "9px 15px"
                        }
                    }
                },
                MuiStepIcon: {
                    styleOverrides: {
                        root: {
                            height: "40px",
                            width: "40px"
                        }
                    }
                },
                MuiStepper: {
                    styleOverrides: {
                        root: {
                            marginBottom: "30px"
                        }
                    }
                },
                MuiStepLabel: {
                    styleOverrides: {
                        root: {
                            fontSize: "12px"
                        },
                        label: {
                            fontSize: "12px"
                        }
                    }
                },



                // MuiSelect:{
                //     styleOverrides:{
                //         root:{
                //             marginBottom:'50px'
                //         }
                //     }
                // },
                // MuiListItem:{
                //     styleOverrides:{
                //         root:{
                //             marginBottom:'50px'
                //         }
                //     }
                // },
                // MuiListItem:{
                //     styleOverrides:{
                //         root:{
                //             padding:'10px 20px',
                //             color:'black'
                //         }
                //     }

                // },

                MuiListItemButton: {
                    styleOverrides: {
                        root: (theme) => ({
                            padding: '10px 20px',
                            margin: '0',

                            ".MuiListItemText-primary": {
                                color: theme.theme.palette.text.secondary,
                            },


                            '&.active': {
                                ".MuiSvgIcon-root": {
                                    color: theme.theme.palette.primary.main,
                                },

                                ".MuiListItemText-primary": {
                                    fontWeight: 700,
                                },
                            },
                        }),
                    }
                },

                MuiListItemIcon: {
                    styleOverrides: {
                        root: {
                            minWidth: '16px',
                            minHeight: '2.2rem',
                            marginRight: '10px',
                        }
                    }
                },
                MuiListItemText: {
                    styleOverrides: {

                        root: {
                            margin: '0',
                        },

                        primary: {
                            fontSize: '1.6rem',
                            lineHeight: '2.2rem',
                            fontWeight: 400,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }
                    }

                },
                // MuiTabs:{
                //     styleOverrides:{
                //         root:{
                //             fontSize:'20px',
                //             lineHeight:'28px'
                //         }
                //     }
                // },
                MuiTab: {
                    styleOverrides: {
                        root: {
                            fontSize: '20px',
                            lineHeight: '28px',
                            padding: '16px 40px',
                            overflow: 'hidden',

                            "@media (max-width: 576px)": {
                                fontSize: '16px',
                                lineHeight: '20px',
                                padding: '8px 20px',
                            }
                        }
                    }
                },
                // MuiInp:{
                //     styleOverrides:{
                //         root:{
                //             textarea
                //         }
                //     }
                // }

                

                MuiDialog: {
                    styleOverrides: {
                        root: {

                            ".MuiDialog-paper": {
                                maxWidth: '100%',
                                backgroundColor: '#262632',
                                borderRadius: '10px',
                                '@media (max-width: 600px)': {
                                    margin: '15px'
                                }
                            },
                            '&.sizeSmall': {
                                ".MuiDialog-paper": {
                                    width: '400px',

                                }
                            },
                            '&.sizeMedium': {
                                ".MuiDialog-paper": {
                                    width: '600px',

                                }
                            },
                            '&.sizeLarge': {
                                ".MuiDialog-paper": {
                                    width: '1000px',

                                }
                            },
                            '&.sizeExtraLarge': {
                                ".MuiDialog-paper": {
                                    width: '1400px',

                                }
                            },


                        }
                    }
                },
                MuiDialogTitle: {
                    styleOverrides: {
                        root: {
                            padding: '20px 24px',
                            backgroundColor: '#262632',
                            color: '#75758E'
                        },
                    },


                },
                MuiDialogContent: {
                    styleOverrides: {
                        root: {
                            padding: '20px 24px ',
                            backgroundColor: '#262632'
                        },

                    }
                },
                MuiDialogActions: {
                    styleOverrides: {
                        root: {
                            padding: '20px 24px ',
                            backgroundColor: '#262632',
                            color: '#75758E'

                        }
                    }
                },

                MuiTooltip: {
                    styleOverrides: {
                        tooltip: {
                            fontSize: '1.2rem'
                        }
                    }
                },
                MuiStepButton: {
                    styleOverrides: {
                        root: {
                            ".MuiStepLabel-label": {
                                textAlign: "left",
                                marginLeft: "10px"
                            }
                        }
                    }
                }
            }
        })
}



export const getTheme = (mode: string) => {

    return createTheme(generateBase({ mode }))

}