import React from 'react';
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom/client';
import AppWrapper from './App';
import reportWebVitals from './reportWebVitals';
import './index.scss';
import store from './store'
import { CookiesProvider } from 'react-cookie';

import './assets/i18n/config'


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);



root.render(
  <Provider store={store}>
    <CookiesProvider defaultSetOptions={{ path: "/" }}>
        <AppWrapper />
    </CookiesProvider>
  </Provider>
);

reportWebVitals();
