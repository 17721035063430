import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import style from './profile.module.scss'
import { useTranslation } from 'react-i18next'
import { gql, useMutation } from '@apollo/client'
import { updateAccessTokenAndLoginStatus } from '../../../store/reducers/AuthSlice';
import { useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';


const LOGOUT_QUERY = gql`mutation Mutation($refreshToken: String!) {
    Logout(refreshToken: $refreshToken) {
      errors
      success
      token {
        accessToken
        refreshToken
      }
    }
  }`



export default function ProfileMenu() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const open = Boolean(anchorEl);
    

    const [cookies, setCookie, removeCookie] = useCookies(['token', 'refreshToken']);
    const refreshToken = cookies.refreshToken;

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        try {
            localStorage.removeItem('redirectPath');
            removeCookie('refreshToken', { path: '/' });
            removeCookie('token', { path: '/' });
            dispatch(updateAccessTokenAndLoginStatus({ token: null, refreshToken: null, isLoggedIn: false }))
            navigate('/login')
        } catch (error) {
            console.log("Logout failes", error)
        }
    }

    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Tooltip title={t("Account Settings")}>

                <IconButton
                    // color={open ? 'primary' : 'common.white'}
                    onClick={handleClick}
                    className={style.icon}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    sx={{
                        'height': '21px',
                        'width': '18px',
                        'color': open ? '#2e9fff' : '#fff'
                    }}
                >
                    <i className="fa-regular fa-user"></i>
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >

              
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    {t('Logout')}
                </MenuItem>
            </Menu>
         
        </React.Fragment>

    );
}