import ModalWidget from "../hoc/modal/Modal";
import { GetWidgetProps, WidgetProps, ButtonProps, ModalProps, SearchInputProps, TableProps, PaginationWidgetProps, FilterMenuProps, IconButtonProps, ActionMenuItemProps, LabelProps } from "../types/widget-types";
import { Box, Button, Grid, IconButton, Link, MenuItem as MuiMenuItem, Paper, TextField, Typography } from '@mui/material'
import SearchInput from "./search-Input/SearchInput";
import DataTable from "./table/table.component";
import PaginationRounded from "./pagination/pagination.component";
import FilterMenu from "./filtermenu/FilterMenu";
import { useTranslation } from 'react-i18next'




export function getWidget(payload: WidgetProps) {
    switch (payload.type) {
        case 'button':
            return <GetButton {...payload} />;
        case 'icon-button':
            return getIconButton(payload);
        case 'modal':
            return <GetModal {...payload} />;
        case 'filter-menu':
            return getFilterMenu(payload);
        case 'action-menu-item':
            return getActionMenuItem(payload);
        case 'search-input':
            return <GetSearchInput {...payload} />;
        case 'table':
            return getTable(payload);
        case 'pagination':
            return getPagination(payload);
        case 'filter-menu':
            return getFilterMenu(payload);
        case 'label-season':
            return getLabelSeason(payload);
        default:
            return <></>;
    }
}




export function GetButton(props: ButtonProps) {
    const { t } = useTranslation();

    props = {
        ...props,
        variant: props.variant ?? 'contained',
        color: props.color ?? 'primary',
        startIcon: props.startIcon ?? null,
    }
    return (<Button variant={props.variant} color='primary' onClick={props.onClick} startIcon={props.startIcon}>
        {t(props.label)}
    </Button>);
}


export function getLabelSeason(props: LabelProps) {
    props = {
        ...props,
        variant: props.variant ?? "subtitle1",
        color: props.color ?? 'text.secondary',
        label:props.label
    }

    return (
        <Typography variant={props.variant} component="p" gutterBottom color={props.color} sx={{'fontSize':'16px','marginBottom':'0px'}}>
            {props.label}
        </Typography>
    )
}

export function getIconButton(props: IconButtonProps) {
    props = {
        ...props,
        variant: props.variant ?? 'contained',
        color: props.color ?? 'primary',
    }
    return (<IconButton size="large" color={props.color} onClick={props.onClick} sx={props.sx}>
        {props.icon}
    </IconButton>);
}

export function GetModal(props: ModalProps) {
    const { t } = useTranslation();
    return <ModalWidget title={t(props.title)} size={props.size} actions={props.actions} modalOpen={props.modalOpen} onClose={props.onClose} onOpen={props.onClose} >
        {props.children}
    </ModalWidget>;
}

export function getFilterMenu(props: FilterMenuProps) {
    return <FilterMenu button={props.button} children={props.children} />
}

export function GetSearchInput(props: SearchInputProps) {
    const { t } = useTranslation();

    return <SearchInput placeholder={t(props.placeholder)} onChange={props.onChange} onSubmit={props.onSubmit} />
}

export function getTable(props: TableProps) {
    return <DataTable columns={props.columns} dataSource={props.dataSource} />
}

export function getPagination(props: PaginationWidgetProps) {
    return <PaginationRounded
        totalItems={props.totalItems}
        itemsPerPage={props.itemsPerPage}
        currentPage={props.currentPage}
        onChange={props.onChange}
    />
}

export function getActionMenuItem(payload: ActionMenuItemProps) {
    return <MuiMenuItem onClick={payload.onClick}>{payload.label}</MuiMenuItem>
}



// export function getFilterMenu(props:filterMenuProps){
//     return <AccountMenu menuItems= {props.menuItems}/>
// }