import * as React from 'react';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import Tooltip from '@mui/material/Tooltip';

export default function EventBadge({ eventCount, eventNames }: any) {
    return (

        <Tooltip title={eventNames.join(", ")} placement='top-start' arrow>
            <Badge badgeContent={eventCount} color="primary">
                <EventAvailableIcon style={{ height: '25px', width: '25px' }} />
            </Badge>
        </Tooltip>
    );
}
